import state from './state';

export default {
	namespaced: true,
	state: state,
	getters: {
		getDataObj(state) {
			return state.dataObj;
		},
		getCompanys(state) {
			return state.companys;
		},
	},
	mutations: {
		setDataObj(state, res) {
			state.dataObj = res;
		},
		setCompanys(state, res) {
			state.companys = res;
		},
	},
};
