import { createApp } from 'vue'
import App from './App.vue'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import router from "./router";
import store from "./store"

import 'bootstrap';
import './styles/global.scss';
import "d3v4"
import VueScreen from 'vue-screen'

const options = {};

createApp(App)
    .use(router)
    .use(store)
    .use(Toast, options)
    .use(VueScreen, 'bootstrap')
    .mount('#app')
