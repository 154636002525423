import axios_http from 'axios';
import store from '../store/';


const BASE_URL = 'https://survey-api.petpla.net'
	/*process.env.NODE_ENV === 'development'
		? 'https://dev-survey-api.petpla.net'
		: 'https://survey-api.petpla.net';*/
const axios = axios_http.create({
	baseURL: `${BASE_URL}`,
});

axios.interceptors.request.use(
	(cfg) => {
		const loggedIn = store.getters['auth/isLoggedIn'];

		if (loggedIn) {
			cfg.headers[
				'Authorization'
				] = `Bearer ${store.getters['auth/getAccessToken']}`;
		}

		return cfg;
	},
	(err) => {
		console.error(err);
	},
);

const withAxios = async (handler) => {
	let error = null;
	let response = null;

	try {
		response = await handler(axios);
		error = null;
	} catch (err) {
		error = err;
	}

	return { error, response };
};

export { axios, BASE_URL, withAxios };
