import { withAxios } from '../../boot/axios';


const fetchTest = async () => {
	const { error, response } = await withAxios((a) => a.get("/countries/userCountries"));

	return {
		error,
		response,
		data: response?.data?.data || null,
	};
};

const API = {
	fetchTest,
};

export { API };
