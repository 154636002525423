<template>
  <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet">
  <div class="d-flex flex-column h-100">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  watch: {
    $route(to) {
      if (to.meta.title !== undefined) {
        document.title = `${to.meta.title} | PETpla.net` ;
      } else {
        document.title = "PETpla.net";
      }
    },
  },
  async created() {
    this.$store.commit(
        "auth/setAccessToken",
        localStorage.getItem("auth/access_token")
    );
  }
}
</script>

<style>
::-webkit-scrollbar {
  height: 9px;
}

::-webkit-scrollbar-track {
  background-color: whitesmoke;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
}
.wrapper {
  position: relative;
}

.statistic {
  height: 450px;
}

.loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.multiselect-clear{
  display: none!important;
  visibility: hidden!important;
}
</style>