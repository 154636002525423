import {createRouter, createWebHistory} from "vue-router";
import store from '../store/index';

const routes = [
    {
        path: "/",
        component: () => import("../layouts/mainLayout"),
        children: [
            {
                path: "",
                component: () => import("../views/dashboard"),
                meta: {title: "Dashboard"}
            }
        ]
    },
    {
        path: "/login",
        component: () => import("../layouts/emtyLayout"),
        children: [
            {
                path: "",
                component: () => import("../views/logIn"),
                meta: {title: "Login"}
            }
        ]
    },
    {
        path: "/:pathMatch(.*)",
        name: "404",
        component: () => import("../layouts/emtyLayout"),
        children: [
            {
                path: "",
                component: () => import("../views/404"),
                meta: {title: "404"}
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});


// Check login
router.beforeEach(async (to, from, next) => {
    if (to.path === '/login') return next();

    if (!store.state.auth.isTokenValid) {
        await store.dispatch('auth/validateToken');
    }

    if (!store.state.auth.isTokenValid) {
        next(
            '/login',
        );
        return;
    }
    next();
});

router.beforeEach(async (to, from, next) => {
    next();
});

export default router;
