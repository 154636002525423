import {API} from '@/helper/api-controller';
import state from './state';

export default {
    namespaced: true,
    state: state,
    actions: {
        async validateToken({state}) {
            const {error} = await API.fetchTest();
            if (error) {
                state.isTokenValid = false;
                return;
            }
            state.isTokenValid = true;
        },
    },
    getters: {
        getAccessToken(state) {
            return state.access_token;
        },
        isLoggedIn(state) {
            return !(state.access_token === '');
        },
    },
    mutations: {
        setAccessToken(state, token) {
            state.access_token = token;
        },
        clearTokens(state) {
            state.access_token = '';
            state.isTokenValid = false;
        },
    },
};
