import { createStore } from 'vuex';
import survey from './survey/index'
import auth from './auth/index'

export default createStore({
	modules: {
		survey,
		auth,
	},

	//Toaster anfang
	state: {
		toast: {
			state: false,
			text: '',
		},
		currentPage: '',
	},
	mutations: {
		setToastState(state, toast) {
			state.toast = toast;
		},
		async setCurrentPage(state, value) {
			state.currentPage = value;
		},
	},
	getters: {
		getToastState(state) {
			return state.toast;
		},
	},
	actions: {
		async showToast(context, options) {
			options.text = options.text || '';
			options.delay = options.delay || 3 * 1000;
			options.type = options.type || 'warning';

			const typeClass =
				options.type === 'success'
					? 'bg-success'
					: options.type === 'warning'
						? 'bg-danger'
						: 'bg-danger';

			context.commit('setToastState', {
				state: true,
				text: options.text,
				class: `rounded p-2 m-2 ${typeClass}`,
			});

			setTimeout(() => {
				context.commit('setToastState', {
					state: false,
					text: '',
					class: `rounded p-2 m-2 ${typeClass}`,
				});
			}, options.delay);
		},
	}, 	//Toaster Ende


});
